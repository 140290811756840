export const themes = {
  light: {
    '--textColor': "#333",
    '--light': '#fff',
    '--bg': "#e4f0fa",
    '--tarbarBg': "#fff",
    '--navBg': "#fff",
    '--navColor': '#333',
    '--gray1': "#999",
    '--gray2': "#6a82ad",
    '--theme': "#00bbfe",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#6c7b9d',
    '--tarbarActiveColor': '#00a6fe',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)'
  },
  a6406Light: {
    '--textColor': "#333",
    '--light': '#fff',
    '--bg': "#e4f0fa",
    '--tarbarBg': "#2a2b35",
    '--navBg': "#cd0000",
    '--navColor': '#fff',
    '--gray1': "#999",
    '--gray2': "#6a82ad",
    '--gray_new': '#b8ecff',
    '--gray3': '#b8ecff',
    '--theme': "#00bbfe",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#fff',
    '--tarbarActiveColor': '#00a6fe',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)',
    '--moduleColor': '#06314d',
    '--moduleSizeColor': '#00bbfe',
    '--btnsizeColor': '#fff',
    '--gameSizeColor': '#b8ecff',
    '--hallSizecolor': '#fff7b7',
    '--hallBg': '#0d192c',
    '--gameBg': '#07304a',
    '--valueColor': '#fea700'
  },
  a6419Light: {
    '--textColor': "#333",
    '--light': '#fff',
    '--bg': "#e4f0fa",
    '--tarbarBg': "#2a2b35",
    '--navBg': "#cd0000",
    '--navColor': '#fff',
    '--gray1': "#999",
    '--gray2': "#6a82ad",
    '--gray_new': '#b8ecff',
    '--theme': "#00bbfe",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#fff',
    '--tarbarActiveColor': '#00a6fe',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)',
    '--moduleColor': '#06314d',
    '--moduleSizeColor': '#00bbfe',
    '--btnsizeColor': '#fff',
    '--gameSizeColor': '#b8ecff',
    '--hallSizecolor': '#fff7b7',
    '--hallBg': '#0d192c',
    '--gameBg': '#07304a',
    '--valueColor': '#fea700',

    '--moduleColor2': '#c4bdaa',
    '--moduleColor': '#c4bdaa',
    '--light1': "#F8F6E9",
    '--gameBg': '#F8F6E9',
    '--gray_new': "#715C56",
    '--hallBg': '#715C56',
  },
  heartLight: {
    '--textColor': "#333",
    '--light': '#fff',
    '--bg': "#FCFCFC",
    '--tarbarBg': "#fff",
    '--navBg': "#f65b00",
    '--navColor': '#fff',
    '--gray1': "#999",
    '--gray2': "#666",
    '--theme': "#f65b00",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#6c7b9d',
    '--tarbarActiveColor': '#00a6fe',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)'
  },
  A6301: {
    '--textColor': "#333",
    '--light': '#fff',
    '--bg': "#f2f2f5",
    '--tarbarBg': "#fff",
    '--navBg': "#fff",
    '--navColor': '#333',
    '--gray1': "#999",
    '--gray2': "#6a82ad",
    '--theme': "#00bbfe",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#666',
    '--tarbarActiveColor': '#7e5678',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)'
  },
  a6501_2: {
    '--textColor': "#333",
    '--light': '#fff',
    '--bg': "#FCFCFC",
    '--tarbarBg': "#fff",
    '--navBg': "#f65b00",
    '--navColor': '#fff',
    '--gray1': "#999",
    '--gray2': "#666",
    '--theme': "#f65b00",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#6c7b9d',
    '--tarbarActiveColor': '#00a6fe',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)'
  },
  a6420light: {
    '--textColor': "#333",
    '--light': '#fff',
    '--bg': "#0c192c",
    '--tarbarBg': "#fff",
    '--navBg': "#fff",
    '--navColor': '#333',
    '--gray1': "#999",
    '--gray2': "#6a82ad",
    '--theme': "#00bbfe",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#6c7b9d',
    '--tarbarActiveColor': '#00a6fe',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)',
    '--colors': '#878e97',
    '--gameSizeColor': '#a9bed8'
  },
  a6421light: {
    '--textColor': "#131313",
    '--light': '#fff',
    '--bloack':'#000',
    '--bg': "#f1f1f1",
    '--tarbarBg': "#fff",
    '--navBg': "#fff",
    '--navColor': '#333',
    '--gray1': "#999",
    '--gray2': "#6a82ad",
    '--theme': "#00bbfe",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#909090',
    '--tarbarActiveColor': '#ff4941',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)',
    '--colors': '#999',
    '--gameSizeColor': '#a9bed8',
    '--border1': '#979797',
    '--btn': '#ff4941',
    '--red': '#FF4941',
    '--gray3': '#666',
    '--gray4': '#6F6F6F',
  },
  a6422light: {
    '--textColor': "#333",
    '--light': '#fff',
    '--bg': "#e4f0fa",
    '--tarbarBg': "#2a2b35",
    '--navBg': "#cd0000",
    '--navColor': '#fff',
    '--gray1': "#999",
    '--gray2': "#6a82ad",
    '--gray_new': '#b8ecff',
    '--gray3': '#b8ecff',
    '--theme': "#00bbfe",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#fff',
    '--tarbarActiveColor': '#00a6fe',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)',
    '--moduleColor': '#06314d',
    '--moduleSizeColor': '#00bbfe',
    '--btnsizeColor': '#fff',
    '--gameSizeColor': '#b8ecff',
    '--hallSizecolor': '#fff7b7',
    '--hallBg': '#0d192c',
    '--gameBg': '#07304a',
    '--valueColor': '#fea700'
  },
  a6423light: {
    '--textColor': "#131313",
    '--light': '#fff',
    '--black':'#000',
    '--bg': "#f0f0f0",
    '--tarbarBg': "#fff",
    '--navBg': "#fff",
    '--navColor': '#333',
    '--gray1': "#999",
    '--gray2': "#6a82ad",
    '--theme': "#b3965a",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#909090',
    '--tarbarActiveColor': '#ff4941',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)',
    '--colors': '#b3965a',
    '--gameSizeColor': '#a9bed8',
    '--border1': '#b3965a',
    '--btn': '#b3965a',
    '--red': '#FEF5E3',
    '--gray3': '#666',
    '--gray4': '#6F6F6F',
    '--moduleBg':'#FBFAF2'
  },
}
